$( document ).ready(function() {

 

  $('.Box.g1').matchHeight();
  $('.Box.g2').matchHeight();
  $('.Box.g3').matchHeight();

   $('.Content-section').matchHeight();

  if($(".sidebars").length != 0)
  {
    // All sides
    var sides = ["left", "top", "right", "bottom"];
  
    // Initialize sidebars
    for (var i = 0; i < sides.length; ++i) {
        var cSide = sides[i];
        $(".sidebar." + cSide).sidebar({side: cSide});
    }

    // Click handlers
    $(".sidebar-link[data-action]").on("click", function () {
        var $this = $(this);
        var action = $this.attr("data-action");
        var side = $this.attr("data-side");
        $(".sidebar." + side).trigger("sidebar:" + action);
        return false;
    });

    $("#CanvasArea").canvasAreaDraw();
    $(".colorpicker").spectrum({
      preferredFormat: "hex",
      showInput: true
    });
}
});